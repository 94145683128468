<template>
<!--  <div class="default-select"-->
<!--       :class="{'default-select&#45;&#45;active' : selected && selected.length > 0}"-->
<!--  >  -->
    <div class="multi-select"
       :class="{
          'multi-select--active' : vSelectModel,
          'searchable-off' : searchable === false,
          'disabled' : disabled === true
       }"
  >
    <v-select
        :options="options"
        @input="onChange"
        v-model="vSelectModel"
        multiple
        :label="optionsLabel"
        append-to-body
        :calculate-position="withPopper"
        :filter-by="myFilter"
        :disabled="disabled"
        :searchable="searchable"
        :selectable="() => (vSelectModel && maxLimit) ? vSelectModel.length < maxLimit : true"
    >
      <template #header>
        <div class="v-select__label">{{ label }}</div>
      </template>


      <template slot="option" slot-scope="option" v-if="otherValue !== ''">
        <div class="d-flex" v-if="otherValue === 'requestFundsTransactions'">
          #{{option.id}} :
          ${{option.user_amount}}

          <span class="no-transaction-option black-color" v-if="option.tracking_number">&nbsp; *</span>
        </div>

        <div class="d-flex" v-if="otherValue === 'consolidationOrdersFBM'">
          #{{option.id}} :
          {{option.recipient_contact_name}}
          <template v-if="option.delivery_method">
            ({{option.delivery_method.name}})
          </template>
        </div>

        <div class="d-flex" v-if="otherValue === 'APCDelcon'">
          #{{option.id}} :
          {{option.full_name}},
          {{option.tracking_number}},
          <template v-if="option.delivery_method">
            ({{option.delivery_method.name}})
          </template>
        </div>

        <!-- *********************** PROFORM HS CODE *********************** -->
        <div class="d-flex flex-column" v-if="otherValue === 'proformHSCode'">
          {{ option.proform_value.translationStorage.ua.name }}
        </div>

        <div class="d-flex" v-if="otherValue === 'consolidationMeest'">
          <template>#{{option.id}}</template>
          <template v-if="option.return_parcel">; {{$t('returnGoods_returnedPackageID.localization_value.value')}}: #{{option.return_parcel.id}}</template>
          <template v-if="option.return_parcel">; {{$t('returnGoods_trackingNumber.localization_value.value')}}: {{option.return_parcel.tracking_number}}</template>
        </div>

        <div class="d-flex flex-column" v-if="!otherValue">
          {{ option[optionsLabel] }}
        </div>
      </template>


      <template #selected-option="option" v-if="otherValue !== ''">
        <template v-if="otherValue === 'requestFundsTransactions'">
          #{{option.id}} :
          ${{option.user_amount}}

          <span class="no-transaction-option" v-if="option.tracking_number">&nbsp; *</span>
        </template>

        <template v-if="otherValue === 'consolidationOrdersFBM'">
          #{{option.id}} :
          {{option.recipient_contact_name}}
          <template v-if="option.delivery_method">
            ({{option.delivery_method.name}})
          </template>
        </template>

        <!-- *********************** PROFORM HS CODE *********************** -->
        <template v-if="otherValue === 'proformHSCode'">
          {{option.proform_value.translationStorage.ua.name}}
        </template>

        <template v-if="otherValue === 'APCDelcon'">
          #{{option.id}} :
          {{option.full_name}},
          {{option.tracking_number}},
          <template v-if="option.delivery_method">
            ({{option.delivery_method.name}})
          </template>
        </template>

        <template v-if="otherValue === 'consolidationMeest'">
          <template>#{{option.id}}</template>
          <template v-if="option.return_parcel">; {{$t('returnGoods_returnedPackageID.localization_value.value')}}: #{{option.return_parcel.id}}</template>
          <template v-if="option.return_parcel">; {{$t('returnGoods_trackingNumber.localization_value.value')}}: {{option.return_parcel.tracking_number}}</template>
        </template>

        <template v-if="!otherValue">
          {{option[optionsLabel]}}
        </template>
      </template>


      <template slot="no-options" @click="$refs.select.open = false">
        <div v-if="defaultNoOptions">
          {{$t(`${defaultNoOptions}.localization_value.value`)}}
        </div>
        <div v-else>
          {{$t('common_SorryMatchingOptions.localization_value.value')}}
        </div>
      </template>

    </v-select>

    <span class="multi-select__error error-field" v-if="error && errorTxt">{{errorTxt}}</span>
    <span class="multi-select__caption" v-if="caption">{{caption}}</span>
  </div>
</template>

<script>
  import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core'

  export default {
    name: "MultiSelect",
    components: {
      vSelect
    },

    // props: [
    //   'options',
    //   'selected',
    //   'error',
    //   'errorTxt',
    //   'label',
    //   'caption',
    //   'optionsLabel',
    //   'searchable',
    //   'maxLimit',
    // ],

    props: {
      options: Array,
      selected: [Object, String, Boolean, Array],
      error: [Boolean],
      errorTxt: [String],
      label: [String],
      caption: [String],
      optionsLabel: [String],
      searchable: {
        type: [Boolean],
        default: false,
      },
      maxLimit: {
        type: [Number, String, Boolean],
        default: false,
      },
      defaultNoOptions: {
        type: [String, Boolean],
        default: false,
      },
      disabled: {
        type: [Boolean],
        default: false,
      },
      otherValue: String,
    },

    data(){
      return{
        vSelectModel: '',
        placement: 'bottom',

        myFilter: (option, label, search) => {

          if(this.otherValue === 'requestFundsTransactions'){
            let temp = search.toLowerCase();
            return (option.id+'').toLowerCase().indexOf(temp) > -1 || option.user_amount.toLowerCase().indexOf(temp) > -1
          }

          if(this.otherValue === 'consolidationOrdersFBM'){
            let temp = search.toLowerCase();
            return (option.id+'').toLowerCase().indexOf(temp) > -1 ||
                option.recipient_contact_name.toLowerCase().indexOf(temp) > -1
          }

          if(this.otherValue === 'APCDelcon'){
            let temp = search.toLowerCase();
            return (option.id+'').toLowerCase().indexOf(temp) > -1 ||
                option.tracking_number.toLowerCase().indexOf(temp) > -1 ||
                option.full_name.toLowerCase().indexOf(temp) > -1
          }

          if(this.otherValue === 'proformHSCode') {
            let temp = search.toLowerCase();
            return option?.proform_value?.translationStorage?.ua?.name.toLowerCase().indexOf(temp) > -1
          }

          return (label || '').toLowerCase().indexOf(search.toLowerCase()) > -1
        }
      }
    },

    mounted() {
      console.log(this.selected);
      this.vSelectModel = this.selected;
    },

    watch: {
      selected: function(newVal) {
        this.vSelectModel = newVal;
      }
    },

    methods: {


      onChange(){
        this.$emit('change', this.vSelectModel);
      },

      withPopper (dropdownList, component, {width}) {
        /**
         * We need to explicitly define the dropdown width since
         * it is usually inherited from the parent with CSS.
         */
        dropdownList.style.width = width;

        console.log(55);
        /**
         * Here we position the dropdownList relative to the $refs.toggle Element.
         *
         * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
         * the dropdownList overlap by 1 pixel.
         *
         * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
         * wrapper so that we can set some styles for when the dropdown is placed
         * above.
         */
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.placement,
          modifiers: [
            {
              name: 'offset', options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({state}) {
                component.$el.classList.toggle('drop-up', state.placement === 'top')
              },
            }]
        });

        /**
         * To prevent memory leaks Popper needs to be destroyed.
         * If you return function, it will be called just before dropdown is removed from DOM.
         */
        return () => popper.destroy();
      },

    }
  }
</script>

<style lang="scss" >
  @import "../../../../scss/colors";
  @import "../../../../scss/mixins/mixins";
  @import "vue-select/src/scss/vue-select.scss";

  .no-transaction-option{
    font-size: 12px;
    position: relative;
    top: -5px;
    color: white;

    &.black-color{
      color: $black;
    }
  }


  .multi-select{
    position: relative;


    &--active {
      .v-select__label {
        top: -5px;
        background: white;
        background: linear-gradient(0deg, #F8F4EE 0%, #FFFFFF 105.56%);
        padding: 0 2px;
        font-size: 10px;
        line-height: 12px;
      }

      .v-select .vs__dropdown-toggle{
        border-color: #CD9E64;
      }
    }


    &.ui-no-valid .v-select .vs__dropdown-toggle{
      border: 2px solid $orange;
    }


    &__error{
      position: absolute;
      bottom: -18px;
      right: 0;
      font-size: 11px;
      line-height: 13px;
      color: $orange;
    }

    &__caption{
      width: 100%;
      text-align: right;
      font-size: 11px;
      line-height: 13px;
      color: $borderBrown;
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;
      margin-bottom: -10px;
    }


    &.white{


      .v-select:not(.vs--single) .vs__selected-options .vs__selected{
        background: white;
        border: 0;
        color: $black;
      }

      .v-select:not(.vs--single) .vs__selected-options .vs__selected .vs__deselect svg path{
        fill: $brown;
      }

      .v-select:not(.vs--single) .vs__selected-options .vs__selected .vs__deselect{
        top: 8px;
      }
    }


    .vs__dropdown-toggle{
      padding-bottom: 9px;
    }
  }



    .vs__no-options{
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include for-550 {
        font-size: 13px;
      }
    }

    .vs__selected{
      padding: 0;
      margin: 0;
      top: 8px;
    }

    .vs__dropdown-toggle{
      padding-left: 16px;
      min-height: 42px;
      background: #F8F4EE;
      border: 1px solid #BBAD9C;
      box-sizing: border-box;
      border-radius: 5px;
    }

    .vs--open .vs__dropdown-toggle{
      border-color: #CD9E64;
    }

    .vs__search, .vs__search:focus{
      padding: 0;
    }



    .v-select__label{
      position: absolute;
      left: 16px;
      top: 13px;
      font-size: 14px;
      line-height: 16px;
      color: #BBAD9C;
      transition: 0.15s all;
    }

    .vs--open .v-select__label{
      top: -5px;
      background: white;
      background: linear-gradient(0deg, #F8F4EE 0%, #FFFFFF 105.56%);
      padding: 0 2px;
      font-size: 10px;
      line-height: 12px;
    }

    .vs__actions svg path{
      fill: #8F7A61;
    }

    .vs__dropdown-menu{
      border-radius: 5px 5px 0 0;
      border: 1px solid $mainBg;
      box-shadow: 0px 4px 7px $mainBg;
      padding-top: 0;
      padding-bottom: 0;

      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        border: 1px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        margin-right: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: $borderBrown;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $borderBrown;
        opacity: .5;
      }
    }

    .vs__dropdown-option{
      min-height: 42px;
      display: flex;
      align-items: center;
      color: $black;
      white-space: normal;
      border-bottom: 1px solid $mainBg;

      &:last-child{
        border-bottom: 0;
      }

      &--highlight{
        background: $borderBrownOpacity02;
        color: $black;
      }
    }


  .v-select:not(.vs--single){

    .vs__selected-options{
      padding: 0;
      top: 2px;
      display: flex;
      flex-wrap: wrap;

      .vs__selected{
        color: white;
        background: $brown;
        margin-bottom: 3px;
        margin-right: 5px;
        font-size: 14px;
        line-height: 16px;
        padding: 4px;
        padding-left: 20px;
        position: relative;

        &:last-child{
          margin-bottom: 10px;
        }

        .vs__deselect{
          position: absolute;
          left: 1px;
          top: 7px;

          svg path{
            fill: white;
          }
        }
      }

      .vs__search{
        display: flex;
        width: 100%;
      }

    }
  }



  .searchable-off {

    .vs__selected-options{
      top: 7px!important;
    }

    .vs__search{
      /*display: none!important;*/
      width: 0 !important;
      height: 0 !important;
      overflow: hidden !important;
      padding: 0 !important;
      margin: 0 !important;
    }

    .vs__selected{
      top: 0!important;
      height: 27px!important;
    }
  }


</style>
