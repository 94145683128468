<template>
  <AccordBlock>
    <template slot="header">
      {{$t('consolidations_ordersFBMInc.localization_value.value')}}
    </template>

    <template slot="body">

      <div class="custom-row mt-3">
        <div class="custom-col custom-col--66 custom-col--md-100">
          <MultiSelect
              class="white mb-3"
              v-bind:class="{ 'ui-no-valid': consolidation.validation.relatedOrders }"
              :error="consolidation.validation.relatedOrders"
              :errorTxt="$t(`${consolidation.validationTranslate.relatedOrders}.localization_value.value`)"
              :options="ordersFBM"
              :label="$t('consolidations_selectOrder.localization_value.value')"
              :otherValue="'consolidationOrdersFBM'"
              :selected="consolidation.data.relatedOrders"
              :searchable="true"
              @change="changeSelectedOrders"
          />
        </div>
      </div>

      <div class="order-create__row custom-row"
           v-bind:class="{
        'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()
      }"
      >
        <div class="admin-edit" @click="editTranslate([
          'consolidations_number',
          'consolidations_name',
          'consolidations_deliveryService',
          'consolidations_trackingNumber',
      ])"></div>
        <div class="order-create__col custom-col">


          <div class="site-table-wrap table-small"
               v-if="!isMobileFunc()"
          >
            <table class="site-table"
                   v-if="consolidation.getRelatedOrders().length"
            >
              <thead>
              <tr>
                <th v-if="isAdmin"></th>
                <th class="text-lg-right">#</th>
                <th>{{$t('consolidations_number.localization_value.value')}}</th>
                <th class="white-space-line">{{$t('consolidations_name.localization_value.value')}}</th>
                <th>{{$t('consolidations_deliveryService.localization_value.value')}}</th>
                <th>{{$t('consolidations_trackingNumber.localization_value.value')}}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>

              <tr
                  v-for="(item, index) in consolidation.data.relatedOrders"
                  :key="index"
              >
                <td v-if="isAdmin">
                  <DefaultCheckbox
                      :value="item.orderChecked"
                      @input="(val) => changeOrderChecked(item, val)"
                  />

                </td>
                <td class="white-space-line" align="right" width="40px">
                  <b>{{index + 1}}</b>
                </td>
                <td>
                  <b>#{{item.id}}</b>
                </td>
                <td>
                  <div class="table-row ellipsis">
                    {{item.recipient_contact_name}}
                  </div>
                </td>
                <td>
                  <div>
                    <ValueHelper
                        :value="item"
                        :deep="'delivery_method.name'"
                    />
                  </div>
                </td>
                <td>
                  <div>
                    <ValueHelper
                        :value="item"
                        :deep="'tracking_number'"
                    />
                  </div>
                </td>
                <td width="20%">
                  <div class="table-right table-manage-list">

                    <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                      <template slot="item">
                        <LinkButton
                            :value="$t('common_edit.localization_value.value')"
                            :type="'edit'"
                            :target="'_blank'"
                            :link="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.order_type_id).name + '/' + item.id"
                        />
                      </template>
                      <template slot="item">
                        <LinkButton
                            :value="$t('common_delete.localization_value.value')"
                            :type="'delete'"
                            @click.native="removeOrder(item.id)"
                        />
                      </template>
                    </ManagerButton>
                  </div>
                </td>
              </tr>

              </tbody>
            </table>

          </div>

          <div class="site-table-mobile"
               v-if="isMobileFunc()"
          >
            <div class="site-table-mobile__inner custom-row">
              <div
                  class="custom-col custom-col--50 custom-col--sm-100"
                  v-for="(item, index) in consolidation.getRelatedOrders()"
                  :key="index"
              >
                <div class="site-table-mobile__item">
                  <div class="site-table-mobile__head">
                    {{item.recipient_contact_name}}
                  </div>
                  <div class="site-table-mobile__row custom-row">
                    <div class="site-table-mobile__piece custom-col custom-col--33">
                    <span>
											#{{index + 1}}
										</span>
                    </div>
                    <div class="site-table-mobile__piece custom-col custom-col--66">
                      {{$t('consolidations_number.localization_value.value')}}
                      <span>
											#{{item.id}}
										</span>
                    </div>
                    <div v-if="item.delivery_method" class="site-table-mobile__piece custom-col">
                    <span>
                      <ValueHelper
                          :value="item"
                          :deep="'delivery_method.name'"
                      />
                    </span>
                    </div>
                    <div class="site-table-mobile__piece custom-col">
                      {{$t('consolidations_trackingNumber.localization_value.value')}}
                      <div>
                        <ValueHelper
                            :value="item"
                            :deep="'tracking_number'"
                        />
                      </div>
                    </div>
                    <div class="site-table-mobile__piece custom-col">
                      <div class="table-right table-manage-list">
                        <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                          <template slot="item">
                            <LinkButton
                                :value="$t('common_edit.localization_value.value')"
                                :type="'edit'"
                                :link="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.order_type_id).name + '/' + item.id"
                            />
                          </template>
                          <template slot="item">
                            <LinkButton
                                :value="$t('common_delete.localization_value.value')"
                                :type="'delete'"
                                @click.native="removeOrder(item.id)"
                            />
                          </template>
                        </ManagerButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </template>
  </AccordBlock>
</template>

<script>
  import AccordBlock from "../../../../../../../coreComponents/AccordBlock/AccordBlock";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  import MultiSelect from "../../../../../../../UI/selectiones/MultiSelect/MultiSelect";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import ManagerButton from "../../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import {FBMMixins} from "../../../../../../../../mixins/FBMMixins/FBMMixins";
  import {
    DEUTSCHE_POST_DELIVERY_SERVICE,
    DPD_DELIVERY_SERVICE,
    GLOBAL24_DELIVERY_SERVICE,
    POSTNL_DELIVERY_SERVICE,
  } from "../../../../../../../../staticData/staticVariables";
  import {consolidationMixinHelper} from "../../../../../../../../mixins/consolidationMixins/consolidationMixinHelper";
  import {proformMixins} from "../../../../../../../../mixins/proformMixins/proformMixins";
  import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

  export default {
    name: "Orders",
    components: {DefaultCheckbox, ManagerButton, ValueHelper, LinkButton, MultiSelect, AccordBlock},

    mixins: [
      mixinDetictingMobile,
      FBMMixins,
      consolidationMixinHelper,
      proformMixins
    ],

    props: {
      consolidation: Object,
      orderUserId: {
        type: [Number, String]
      }
    },

    watch: {
      orderUserId() {
        this.initOrders()
      }
    },

    mounted() {
      if (this.consolidation.data.User.getUserId()) {
        this.initOrders()
      }
    },

    data() {
      return {
        ordersFBM: [],
        DPD_DELIVERY_SERVICE: DPD_DELIVERY_SERVICE,
      }
    },

    methods: {
      changeOrderChecked(item, val) {
        item.orderChecked = val
      },

      changeSelectedOrders(val) {

        this.consolidation.setRelatedOrders(val, this)

      },

      initOrders() {
        const query = new this.Query();
        let url = '?';
        let myQuery = query
            .for('posts')

        //select orders with consolidation and label type id
        myQuery.where('OrderFbmTypeId', '1,3')
        myQuery.where('user_id', this.consolidation.data.User.getUserId())
        myQuery.where('consolidationOrderQuery', '1')
        if (this.isDPD) {
          let queryString = this.DPD_DELIVERY_SERVICE.id + ',' + POSTNL_DELIVERY_SERVICE.id + ',' + GLOBAL24_DELIVERY_SERVICE.id + ',' + DEUTSCHE_POST_DELIVERY_SERVICE.id
          myQuery.where('deliveryService', queryString)
        }
        else {
          let queryString = this.DPD_DELIVERY_SERVICE.id + ',' + GLOBAL24_DELIVERY_SERVICE.id + ',' + DEUTSCHE_POST_DELIVERY_SERVICE.id
          myQuery.where('exemptManyDeliveryServices',  queryString)
        }
        //COMMENT AND REDO LATER (optimisation, get proform data after picking order from select)
        // myQuery.appends('proformData')

        url = url + myQuery.limit(100000).page(1).url().split('?')[1]

        this.$store.dispatch('fetchOrdersFBMListForConsolidation', url).then(response => {
          //filter orders in draft
          this.ordersFBM = this.getRespPaginateData(response)
          console.log('orders for popup', this.ordersFBM);
        })
      },

      removeOrder(id) {
        this.consolidation.setRelatedOrders(this.consolidation.getRelatedOrders().filter(item => {
          return item.id !== id
        }))
      }
    }
  }
</script>

<style scoped lang="scss">
  .toggle-block__details {
    min-height: 300px;
  }

  @media (max-width: 1120px){
    .toggle-block__details {
      background: #F8F4EE;
      border: 1px solid #BBAD9C;
      min-height: initial;
    }
  }

  .site-table-wrap td{
    font-size: 12px;
    font-weight: 500 !important;
  }

  .manage-block{
    display: flex;
    align-items: center;
  }

  .table-row--input {
    width: 80px;
  }

  .site-table-mobile__item{
    background: #f8f4ee;

    .default-input-wrap{
      max-width: 100px;
    }
  }

</style>
