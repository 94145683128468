<template>
  <div class="fragment">
    <div class="detail-page">
      <div class="detail-page__left">
        <CardLeftBlock
            :backgroundImage="'order-consolidation'"
        >
          <template slot="body">
            <div class="card-detail-left__date">
              {{ new Date() | moment("DD MMM, YYYY") }}
            </div>
            <div class="mt-4"
                 v-if="consolidation.getRelatedOrders().length > 0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                'consolidations_printLabels',
                ])"></div>
              <MainButton
                  class="btn-fit-content"
                  :value="$t('consolidations_printLabels.localization_value.value')"
                  @click.native="$emit('printLabels')"
              />
            </div>

            <div v-if="consolidation.getLabelFileUUID()" class="mt-4"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                'consolidation_fedexDocs',
                ])"></div>
              <router-link :to="$store.getters.GET_PATHS.getPdfFile + '/' + consolidation.getLabelFileUUID()" :target="'_blank'">
                <MainButton
                    v-bind:class="{'disabled-btn' : $store.getters.getConsolidationBtn}"
                    :value="$t('consolidation_fedexDocs.localization_value.value')"
                />
              </router-link>
            </div>
          </template>
        </CardLeftBlock>
      </div>

      <div class="detail-page__right">
        <CardRightBlock>
          <template slot="header">
            <div class="order-create__head">
              <div class="order-create__head-line">
                <div class="order-create__header-col"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                  'consolidations_backTo',
                  ])"></div>
                  <router-link :to="$store.getters.GET_PATHS.ordersConsolidation" class="order-create__head-back">
                    <LinkBack
                        :value="$t('consolidations_backTo.localization_value.value')"
                    />
                  </router-link>
                </div>
              </div>

              <div
                  v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'consolidations_newConsolidationFedex',
                  // 'consolidations_newConsolidationDHL',
                  // 'consolidations_newConsolidationTNT',
                  'consolidations_newConsolidationEu',
                  'consolidations_newConsolidationYourCity',
                ])"></div>
                <div class="order-create__head-title color-orange"
                     v-if="isTNT">
                  {{$t('consolidations_newConsolidationTNT.localization_value.value')}}
                </div>
                <div class="order-create__head-title color-red"
                     v-if="isDHL">
                  {{$t('consolidations_newConsolidationDHL.localization_value.value')}}
                </div>
                <div class="order-create__head-title color-blue"
                     v-if="isFedex">
                  {{$t('consolidations_newConsolidationFedex.localization_value.value')}}
                </div>
                <div class="order-create__head-title color-blue"
                     v-if="isDHL_USA">
                  {{$t('consolidations_newConsolidationDHLUSA.localization_value.value')}}
                </div>
                <div class="order-create__head-title color-blue"
                     v-if="isDPD">
                  {{$t('consolidations_newConsolidationEu.localization_value.value')}}
                </div>
                <div class="order-create__head-title color-blue"
                     v-if="isEUProduct">
                  {{$t('consolidations_newConsolidationEuProducts.localization_value.value')}}
                </div>
                <div class="order-create__head-title color-blue"
                     v-if="isFromYourCity">
                  {{$t('consolidations_newConsolidationYourCity.localization_value.value')}}
                </div>
              </div>

              <div class="order-create__head-txt"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'consolidations_newConsolidationText',
                  ])"></div>
                {{ $t('consolidations_newConsolidationText.localization_value.value') }}
              </div>

              <div v-if="!isAdmin"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                'consolidations_shipping',
                'consolidations_consolidation',
                'consolidations_products',
                'consolidations_senderInfo',
                'consolidations_confirmation',
              ])"></div>
                <div class="order-create__head-steps">
                  <ProgressSteps
                      class="progress-step-shipments"
                      :options="progressStepsSetting"
                      :step="activeStep"
                      @nextPageHandler="nextPageHandler"
                      @changeStep="stepChange"
                  />
                </div>
              </div>

            </div>
          </template>

          <template slot="body">
            <StepA
                v-if="activeStep === 1 || isAdmin"
                :consolidation="consolidation"
                :destinationData="destinationData"
                :deliveryServices="deliveryServices"
                :loadingFedexWarehouses="loadingFedexWarehouses"
                :isEdit="true"
            />
            <StepB
                v-if="(activeStep === 2 || isAdmin) && !isTNT"
                :consolidation="consolidation"
                :showPromoFields="showPromoFields"
                :responseData="responseData"
            />
            <StepC
                v-if="((activeStep === 3 || isAdmin) && !isTNT) ||
                ((activeStep === 2 || isAdmin) && isTNT)"
                :consolidation="consolidation"
            />
            <StepD
                v-if="((activeStep === 4 || isAdmin) && !isTNT  && isFromAPI && !isOwnCarrier) ||
                ((activeStep === 3 || isAdmin) && isTNT)"
                :consolidation="consolidation"
            />
            <StepFinal
                v-if="((activeStep === 5 && !isTNT && isFromAPI && !isOwnCarrier) ||
                (activeStep === 4 && !isTNT && !isFromAPI && !isOwnCarrier) ||
                (activeStep === 4 && (isTNT || isOwnCarrier))) && !isAdmin"
                :consolidation="consolidation"
                :labelPrice="labelPrice"
                @changeStep="step => {activeStep = step}"
            />
          </template>
          <template slot="footer">
            <div class="order-create__footer">
              <div class="order-create__footer-link">
                <router-link :to="$store.getters.GET_PATHS.ordersConsolidation"
                             class="site-link site-link--alt"
                             v-if="activeStep === 1"
                >
                  {{$t('common_cancel.localization_value.value')}}
                </router-link>
                <span class="site-link site-link--alt"
                      @click="prevPageHandler()"
                      v-if="activeStep !== 1"
                >
                  {{$t('common_Previous.localization_value.value')}}
                </span>
              </div>

              <div class="order-create__footer-btn ml-auto">
                <MainButton
                    class="order-create__footer-btn-i secondary"
                    :value="$t('common_SaveInDrafts.localization_value.value')"
                    :tooltip="true"
                    v-if="activeStep === progressStepsSetting.length || isAdmin"
                    @click.native="$emit('prepareSaveConsolidation', {draft: true, edit: true})"
                    v-bind:class="{'disabled-btn' : $store.getters.getConsolidationBtn}"
                >
                  <template slot="tooltip">
                    <p>
                      <b>{{$t('common_drafts.localization_value.value')}}</b>
                    </p>
                    <p>
                      {{$t('common_savingInDrafts.localization_value.value')}}
                    </p>
                  </template>
                </MainButton>

                <MainButton
                    v-if="activeStep !== progressStepsSetting.length && !isAdmin"
                    class="order-create__footer-btn-i"
                    :value="$t('common_Next.localization_value.value')"
                    @click.native="nextPageHandler()"
                />

                <MainButton
                    v-if="(activeStep === progressStepsSetting.length || isAdmin) &&
                      (isFromAPI && (isFromYourCity &&
                        (Number(CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id) === Number(consolidation.getConsolidationDestination().id) ||
                        Number(CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id) === Number(consolidation.getConsolidationDestination().id))
                      )) &&
                      !hasCreateLabel &&
                      !consolidation.getLabelFileUUID()"
                    class="order-create__footer-btn-i"
                    :value="$t('express_BuyLabel.localization_value.value')"
                    @click.native="getLabelPriceFromCard"
                    v-bind:class="{'disabled-btn' : $store.getters.getConsolidationBtn}"
                />

                <div style="position: relative;" class="order-create__footer-btn-i"
                     v-if="(activeStep === progressStepsSetting.length || isAdmin) && isFromAPI && hasCreateLabel &&
                          (Object.keys(labelPrice).length > 0 && parseFloat(labelPrice.price) !== 0) && !consolidation.getLabelFileUUID()"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                    'consolidations_buyLabelError',
                  ])"></div>
                  <MainButton
                      class="order-create__footer-btn-i"
                      :value="$t('express_CreateLabel.localization_value.value')"
                      @click.native="$emit('createLabel', $route.params.id)"
                      v-bind:class="{'disabled-btn' : $store.getters.getConsolidationBtn || blockBuyLabelConsolidation}"
                  />
                  <span class="default-input-wrap__error error-field error-field--btn" v-if="blockBuyLabelConsolidation">
                    {{$t('consolidations_buyLabelError.localization_value.value')}}
                  </span>
                </div>

                <MainButton
                    v-if="(activeStep === progressStepsSetting.length || isAdmin) &&
                    (!isFromAPI || (isFromYourCity &&
                      (Number(CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id) !== Number(consolidation.getConsolidationDestination().id) &&
                      Number(CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id) !== Number(consolidation.getConsolidationDestination().id))
                    ) ||
                    (isFromYourCity && consolidation.getLabelFileUUID()))"
                    class="order-create__footer-btn-i"
                    :value="$t('common_save.localization_value.value')"
                    @click.native="$emit('prepareSaveConsolidation', {draft: false, edit: true})"
                    v-bind:class="{'disabled-btn' : $store.getters.getConsolidationBtn}"
                />
              </div>
            </div>
          </template>
        </CardRightBlock>
      </div>
    </div>

    <BuyLabelPopup
        v-if="isModalBuyLabelPopup"
        @close="changeBuyLabelPopup(false)"
    />

<!--    <LabelPriceInformationPopup-->
<!--        v-if="showBuyLabelWarningPopup"-->
<!--        @close="showBuyLabelWarningPopup = false"-->
<!--    />-->
  </div>
</template>

<script>
import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock";
import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock";
import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
import ProgressSteps from "../../../../../UI/progress/ProgressSteps/ProgressSteps";
import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
import BuyLabelPopup from "../../../popups/BuyLabelPopup/BuyLabelPopup";
import StepA from "../../chunks/ConsolidationUserBlocks/StepA/StepA";
import StepB from "../../chunks/ConsolidationUserBlocks/StepB/StepB";
import StepC from "../../chunks/ConsolidationUserBlocks/StepC/StepC";
import StepD from "../../chunks/ConsolidationUserBlocks/StepD/StepD";
import StepFinal from "../../chunks/ConsolidationUserBlocks/StepFinal/StepFinal";
import {tabsMixins} from "../../../../../../mixins/creationPageMixins/tabsMixins";
import {consolidationMixin} from "../../../../../../mixins/consolidationMixins/consolidationMixin";
import {
  CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY,
  CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY
} from "../../../../../../staticData/staticVariables";
// import LabelPriceInformationPopup from "../../../popups/LabelPriceInformationPopup/LabelPriceInformationPopup";

export default {
  name: "ConsolidationEditingUser",

  components: {
    // LabelPriceInformationPopup,
    StepA,
    StepB,
    StepC,
    StepD,
    StepFinal,
    BuyLabelPopup,
    MainButton,
    ProgressSteps,
    LinkBack,
    CardRightBlock,
    CardLeftBlock,
  },

  props: {
    consolidation: Object,
    responseData: Object,
    destinationData: Array,
    deliveryServices: Array,
    loadingFedexWarehouses: Boolean,
    showPromoFields: Boolean,
  },

  mixins: [
    tabsMixins,
    consolidationMixin
  ],

  computed: {
    consolidationDestination() {
      if(this.consolidation.data.consolidationDestination === ''){
        return false
      }
      return this.consolidation.data.consolidationDestination?.id
    },
  },

  watch: {
    consolidationDestination(){
      if (this.isFromAPI && !this.isTNT && !this.isOwnCarrier) {
        this.progressStepsSetting = [
          this.$t('consolidations_shipping.localization_value.value'),
          this.$t('consolidations_consolidation.localization_value.value'),
          this.$t('consolidations_products.localization_value.value'),
          this.$t('consolidations_senderInfo.localization_value.value'),
          this.$t('consolidations_confirmation.localization_value.value'),
        ]
      }

      if ((!this.isFromAPI && !this.isTNT) || (this.isFromAPI && this.isOwnCarrier)) {
        this.progressStepsSetting = [
          this.$t('consolidations_shipping.localization_value.value'),
          this.$t('consolidations_consolidation.localization_value.value'),
          this.$t('consolidations_products.localization_value.value'),
          this.$t('consolidations_confirmation.localization_value.value'),
        ]
      }
    },

    activeStep: function () {
      this.hasCreateLabel = false
    },

    labelPrice: function () {
      this.hasCreateLabel = true
    },
  },

  data() {
    return {
      activeStep: 1,
      progressStepsSetting: [
        this.$t('consolidations_shipping.localization_value.value'),
        this.$t('consolidations_consolidation.localization_value.value'),
        this.$t('consolidations_products.localization_value.value'),
        this.$t('consolidations_senderInfo.localization_value.value'),
        this.$t('consolidations_confirmation.localization_value.value'),
      ],
      isModalBuyLabelPopup: false,
      blockBuyLabelConsolidation: false,

      isModalSaveInDraftsPopup: false,
      hasCreateLabel: false,
      labelPrice: {},
      CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY: CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY,
      CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY: CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY,
    }
  },

  mounted() {
    if (this.isTNT) {
      this.progressStepsSetting = [
        this.$t('consolidations_shipping.localization_value.value'),
        this.$t('consolidations_products.localization_value.value'),
        this.$t('consolidations_senderInfo.localization_value.value'),
        this.$t('consolidations_confirmation.localization_value.value'),
      ]
    }
    if ((!this.isFromAPI && (this.isFedex || this.isDHL_USA || this.isDHL || this.isDPD || this.isEUProduct)) ||
        (this.isFromAPI && this.isOwnCarrier)) {
      this.progressStepsSetting = [
        this.$t('consolidations_shipping.localization_value.value'),
        this.$t('consolidations_consolidation.localization_value.value'),
        this.$t('consolidations_products.localization_value.value'),
        this.$t('consolidations_confirmation.localization_value.value'),
      ]
    }
  },

  methods: {
    changeBuyLabelPopup(val){
      this.isModalBuyLabelPopup = val
    },

    showSaveInDraftsPopup() {
      this.isModalSaveInDraftsPopup = true
    },

    closeSaveInDraftsPopup() {
      this.isModalSaveInDraftsPopup = false
    },
  }
}
</script>

<style scoped>
  @media (max-width: 1120px) {

    .detail-page__right {
      min-height: calc(100vh - 250px);
    }
  }
</style>